import cx from 'classnames'
import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react'

import { CheckIcon } from '~/components/UI/Icons'

import css from './styles.module.scss'

export default forwardRef(function Checkbox(
  { className, ...props }: HTMLAttributes<HTMLInputElement>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <span className={cx(className, css.Checkbox)}>
      <input ref={ref} className={css.input} {...props} />
      <span className={css.fakeCheckbox}>
        <CheckIcon className={cx(css.checkIcon)} />
      </span>
    </span>
  )
})
