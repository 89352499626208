import { ReactNode } from 'react'

import {
  FieldPhone,
  formDictionary,
  TemplatesProvider,
} from '@unlikelystudio/react-ecommerce-hooks'

import CtaWithPrice from '~/components/CtaWithPrice'

import { useStyle } from '~/providers/StyleProvider'

import Checkbox from '../Checkbox'

export interface FormTemplateProviderProps {
  children: ReactNode | ReactNode[]
}

export default function FormTemplateProvider({
  children,
}: FormTemplateProviderProps) {
  const fieldContainerStyle = useStyle({
    formsPreset: 'fieldContainer',
  })
  return (
    <TemplatesProvider
      dictionary={formDictionary}
      components={{
        fieldPhone: (props) => <FieldPhone {...props} labelNextToPhoneInput />,
        input(props, ref) {
          switch (props?.type) {
            case 'checkbox':
              return <Checkbox {...props} ref={ref} />
            case 'submit':
              return (
                <CtaWithPrice
                  type={'submit'}
                  isFullscreen={false}
                  price={null}
                  {...props}>
                  {props?.value}
                </CtaWithPrice>
              )
            default:
              return null
          }
        },
        fieldContainer: (props) => {
          return <div {...props} className={fieldContainerStyle} />
        },
      }}>
      {children}
    </TemplatesProvider>
  )
}
